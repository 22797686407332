import React, { useEffect } from "react";

const ThankyouHeader = () => {
  
  return (
    <>
        <header>
         <div className="container">
            <div className="row">
               <div className="col-lg-12 logo text-left">
                  <a href="#"> <img src="/assets/Thankyou/img/logo.png" alt=""/> </a>     
               </div>
          
            </div>
         </div>
      </header>
    </>
  );
};

export default ThankyouHeader;
