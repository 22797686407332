import { React, useReducer } from "react";
const Progressbar = ({ value, className }) => {
  return (
    <>
      <div className={className} style={{display:`${className}`}}>
        <div className="col-lg-12 progrss mt-3">
          <div className="progress">
            <div
              className="progress-bar"
              id="progressBar"
              style={{ width: `${value}`, background: "#3ebbe9" }}
            ></div>
          </div>
          <p className="text-center prgrs_txt" id="progressBar_content">
            Progress {value}
          </p>
        </div>
      </div>
    </>
  );
};
export default Progressbar;
