import React, { useEffect, useState } from "react";
import RadioButton from "../../UI/RadioButton";
// import Progressbar from "../../Includes/Progressbar";

const Question1 = ({
  className,
  slideChange,
  textHeading,
  validation,
  progressBar,
}) => {
  const [checkStatusOne,setcheckStatusOne] = useState("unchecked");
  const [checkStatusTwo,setcheckStatusTwo] = useState("unchecked");
  const nextSlide = async (e) => {
    if(e.target.value == 1){
      setcheckStatusOne("checked");
      setcheckStatusTwo("unchecked");
    }
    if(e.target.value == 2){
      setcheckStatusTwo("checked");
      setcheckStatusOne("unchecked");
    }
    slideChange("slide1");
    progressBar("25%");
  };

  return (
    <>
      <div id="slide1" className={className}>
        <div className="col-lg-12 form_1">
          <div className="col-lg-12 row custom m-0">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 txt-lft">
              <p className="hdding py-2">
                {" "}
                I’d like to see Free, No-Obligation Quotes for:
              </p>
              <ul className="icon_buttons">
                <li className="mr js_me" id="testCheckbox" name="testCheckbox">
                  <label
                    htmlFor="1"
                    className="next_01 next-but sctop"
                  >
                    <div className={`icon_butn smoker4 ${checkStatusOne}`}>
                      <RadioButton
                        value="1"
                        name="question_1"
                        className="radio-button"
                        // classInput="radio"
                        // labelName="YES"
                        // labelClass="label-text next02"
                        onClick={nextSlide}
                        id="1"
                        validation={validation()}
                      />
                      {/* <input type="radio"  className="radio-button" name="coverfor" id="smoker-yes11" value="My Family" onClick={nextSlide}/> */}
                      <img
                        className="original-image"
                        src="/assets/QIL_A/img/me_blue.png"
                      />
                      <img
                        className="active-image"
                        src="/assets/QIL_A/img/me.png"
                      />
                    </div>
                  </label>
                  <p className="cover_text" id="checkbox_text">
                    Just Me
                  </p>
                </li>
                <li className="mr">
                  <label
                    htmlFor="2"
                    className="next_01 next-but sctop"
                  >
                    <div className={`icon_butn smoker4 ${checkStatusTwo}`}>
                      {/* <input type="radio" className="radio-button" name="coverfor" id="smoker-no11" value="My Mortgage"/> */}
                      <RadioButton
                        value="2"
                        name="question_1"
                        className="radio-button"
                        // classInput="radio"
                        // labelName="YES"
                        // labelClass="label-text next02"
                        onClick={nextSlide}
                        id="2"
                        validation={validation()}
                      />
                      <img
                        className="original-image"
                        src="/assets/QIL_A/img/partner_blue.png"
                      />
                      <img
                        className="active-image"
                        src="/assets/QIL_A/img/partner.png"
                      />
                    </div>
                  </label>
                  <p className="cover_text">
                    Me and <br /> My Partner
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Question1;
