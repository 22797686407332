import React from "react";
import Email from "../../UI/Email";
import Telephone from "../../UI/Telephone";
import InputButton from "../../UI/InputButton";
import TextField from "../../UI/TextField";
import { useEmailValidation } from "../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../Hooks/usePhoneValidation";

const ContactDetails = ({
  validation,
  validationMsg,
  setError,
  className,
  backClick,
  trigger,
  loaderClass,
  loaderStatus,
  disabled,
  getValues,
  formSubmit,
  CP_V1Style,
  progressBar,
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();

  const validateSlide = async (e) => {
    e.preventDefault();
    // alert("test");
    const values = getValues();
    var fnameValidation = await trigger("txtFName");
    var lnameValidation,emailEmpty,phoneEmpty;
    if(fnameValidation)
    {
       lnameValidation = await trigger("txtLName");
    }
    else{
      return false;
    }
    if(lnameValidation)
    {
      emailEmpty = await trigger("txtEmail");
    }
    else{
      return false;
    }
    if(emailEmpty)
    {
      phoneEmpty = await trigger("txtPhone");
    }
    else{
      return false;
    }
    // await trigger("txtLName");

    // await trigger("txtEmail");
    // await trigger("txtPhone");
    if(phoneEmpty && emailEmpty && fnameValidation && lnameValidation)
    {
      if ((await phoneValidCheck()) === 1 && (await emailValidCheck()) === 1) {
        formSubmit();
      }
    }
    
  };

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if(getPhoneValidation.data.status_code === 2){
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      }
      else if(getPhoneValidation.data.status_code === 3){
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }
      else {
        return 1;
      }
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email);
      if (getEmailValidation.data.status_code === 0) {
        setError("txtEmail", {
          type: "manual",
          message: "Email Already Exist",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };
 
  return (
    <>
      {/* //     <Progressbar value="100%" className={className} /> */}
      <div id="slide5" className={className}>
        <div className="col-lg-12 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group text-center">
            <label className="mobpad10 py-2">
              Who would this policy be for?
            </label>
            <div className="row_style">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 sizer2">
                <TextField
                  type="text"
                  className="form-control sizer"
                  placeholder="First Name"
                  name="txtFName"
                  validation={validation({
                    required: "Please Enter First Name",
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid First Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                    },
                  })}
                  validationMsg={
                    validationMsg.txtFName && validationMsg.txtFName.message
                  }
                ></TextField>
                {/* <input type="text" className="form-control sizer" placeholder="First Name" /> */}
                <i className="tick fa validate success1 fa-check"></i>
                <span id="post_err" className="error_msg"></span>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 sizer2">
                <TextField
                  type="text"
                  className="form-control sizer"
                  placeholder="Last Name"
                  name="txtLName"
                  validation={validation({
                    required: "Please Enter Last Name",
                    minLength: {
                      value: 2,
                      message: "Please Enter Valid Last Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Last Name is Invalid. Please Recheck",
                    },
                  })}
                  validationMsg={
                    validationMsg.txtLName && validationMsg.txtLName.message
                  }
                ></TextField>
                {/* <input type="text" className="form-control sizer" placeholder="Last Name" /> */}
                <i className="tick fa validate success1 fa-check"></i>
                <span id="post_err" className="error_msg"></span>
              </div>
              <div className="clearfix"></div>


              <div className="col-lg-6 col-md-12 col-sm-12 col-12 input-group form-group sizer2">
                <Email
                  name="txtEmail"
                  className="form-control add_prvcy"
                  placeholder="Email Address"
                  onBlur={emailValidCheck}
                  validation={validation({
                    required: "Please Enter Valid Email Address",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid Email Address",
                    },
                  })}
                  validationMsg={
                    validationMsg.txtEmail && validationMsg.txtEmail.message
                  }
                ></Email>
                {/* <input type="tel" id="txtPhone" name="txtPhone" className="form-control  add_prvcy" value="" placeholder="Email Address"/> */}
                <div className="input-group-append">
                  <span className="input-group-text">
                    <img
                      src="http://funeral-planning.co.uk/splits/0602FP00-P/dist/img/privacy.png"
                      alt=""
                      className="privcy pull-right"
                    />
                  </span>
                </div>
                <i className="tick fa validate success1 fa-check"></i>
                {validationMsg.txtEmail && (
                  <span className="error_msg " id="email_err">
                    {validationMsg.txtEmail.message}
                  </span>
                )}

                {/* <span id="post_err" className="error_msg">{validationMsg.txtEmail.message}</span> */}
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 col-12 input-group form-group sizer2">
                {/* <input type="tel" id="txtPhone" name="txtPhone" className="form-control  add_prvcy" value="" placeholder="Phone Number"/> */}
                <Telephone
                  name="txtPhone"
                  className="form-control  add_prvcy"
                  placeholder="Phone Number"
                  onBlur={phoneValidCheck}
                  validation={validation({
                    required: "Please Enter Valid Phone Number",
                    pattern: {
                      value: /^[0-9]*$/i,
                      message: "Please Enter Valid Phone Number",
                    },
                  })}
                  validationMsg={
                    validationMsg.txtPhone && validationMsg.txtPhone.message
                  }
                ></Telephone>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <img
                      src="http://funeral-planning.co.uk/splits/0602FP00-P/dist/img/privacy.png"
                      alt=""
                      className="privcy pull-right"
                    />
                  </span>
                </div>
                <i className="tick fa validate success1 fa-check"></i>
                {/* <span id="post_err" className="error_msg"></span> */}
                {validationMsg.txtPhone && (
                  <span className="error_msg " id="phone_err">
                    {validationMsg.txtPhone.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 bord text-center p-0">
            <input
              type="button"
              className="next-but btn my-bt next_05"
              value="Compare Quotes >>"
              onClick={validateSlide}
            />
            {/* <!-- <input type="button" id="animabt1" style="display: none;" name="" className="next-but btn my-btttnes anim_ylw" value="Compare Quotes >>"> --> */}
            <div className="col-12 text-center mt-1">
              <a
                id="75%"
                name="backslide5"
                onClick={backClick}
                className="back_btn sctop"
              >
                <img src="/assets/QIL_A/img/arrow.png" alt="" /> Previous{" "}
              </a>
            </div>
            <div className="text-center safe-text">
              <img
                src="/assets/QIL_A/img/ico-safe.png"
                alt="Safe, Secure &amp; Confidential"
                width="12"
                height="15"
              />{" "}
              Safe | Secure | Privacy Protected
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <div className="offset-lg-2 col-lg-8 col-lg-offset-2 terms mob-0">
              <div className="consent text-center">
                {/* <p style="color:#000"> */}
                <p>
                  By submitting your enquiry, you agree to our{" "}
                  <a
                    href="https://www.insured-life.co.uk/privacy-policy/?_ga=2.183440678.1636172330.1624982787-1708974579.1610448288"
                    target="blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  and agree we may use the contact details provided to contact
                  you about your Life Insurance plan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactDetails;
