import React, { useContext,useEffect } from "react";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import  "../../../assets/QIL_A/css/QIL_A.scss";
// import { QIL_AContent} from "../../../Constants/QIL_AContent";
import SplitHeadBanner from "../../Includes/Layouts/SplitHeadBanner";
// import Question1 from "../../Includes/Layouts/Question1";
// import Question2 from "../../Includes/Layouts/Question2";
// import DobSlide from "../../Includes/Layouts/DobSlide";
import Footer from "../../Includes/Layouts/Footer";
import FormQIL_A from "../../Forms/FormQIL_A";
import {useDataIngestion} from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryString";

const QIL_A = () => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const {saveDataIngestion,isLoading}   = useDataIngestion();
  useEffect(() => {
    (async () => {
          await saveDataIngestion(
            visitorParameters.visitor_parameters,
            visitorParameters.data,
            'split_page_load',
            'insured-life/life_insurance/QIL_A',
            queryString
          )
    })();
  }, [visitorParameters]);

  return (
    <div className="QIL_A">
    <SplitHeadBanner/>
    <section className="SECT">
        <div className="container mobsect">
        <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12 pad0">
                <h1 className="d-block d-sm-none d-md-none d-lg-none">Compare <span>Life Insurance</span> Quotes </h1>
                <p className="d-block d-md-none star-t"></p>
                <div className="form_part">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center d-lg-none d-sm-none d-md-none d-block">
    
                        <span className="start-but">Start<br/><img src="/assets/QIL_A/img/arrow-b.png" alt=""/></span>
                    </div>
    
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center d-lg-block d-sm-block d-md-block d-none">
                        <h1 className="">Compare <span>Life Insurance</span> Quotes </h1>
    
                    </div>
                 
                    <div className="clearfix"></div>
                    {/*
                    <!-- ========= PROGRESS BAR AND TEXT START ========= -->*/}
                    <div className="col-lg-12 progrss mt-3 hide" >
                        <div className="progress">
                            {/* <div className="progress-bar" id="progressBar" style="width: 0%; background: #3ebbe9;"></div> */}
                            <div className="progress-bar" id="progressBar" ></div>
                        </div>
                        <p className="text-center prgrs_txt" id="progressBar_content">Progress 0%</p>
                    </div>
                    {/*
                    <!-- ========= PROGRESS BAR AND TEXT END ========= -->*/} {/*
                    <!-- ========= FORM START ========= -->*/}
                    <FormQIL_A/>
                    {/*
                    <!-- ========= FORM END ========= -->*/}
                    </div>
                </div>
            </div>
        </div>
        <div className="clearfix"></div>
    </section>
   
    <Footer/>
    </div>
    
    

   
    
  
     
      
  );
};

export default QIL_A;