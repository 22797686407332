import React, { useEffect, useState, useRef, useContext, useReducer } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
// import Lendors from "../Includes/Layouts/Lendors";
// import BasicDetails from "../Includes/Layouts/BasicDetails";
// import ContactDetails from "../Includes/Layouts/ContactDetails";
import Question1 from "../Includes/LayoutsVWO/Question1";
import Question2 from "../Includes/LayoutsVWO/Question2";
import DobSlide from "../Includes/LayoutsVWO/DobSlide";
import PostcodeDetails from "../Includes/LayoutsVWO/PostcodeDetails";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import QueryStringContext from "../../Contexts/QueryString";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import ContactDetails from "../Includes/LayoutsVWO/ContactDetails";
import Progressbar from "../Includes/Progressbar";

const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  showSlide6: "hide"
 

}
const FormReducer = (state, action) => {
  switch (action.type) {
    case 'showSlide': {
      if (action.payload.clickedSlide.slide == 'slide1') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
         
        }
      } else if (action.payload.clickedSlide.slide == 'slide2') {
        return {
          ...state,
          showSlide2: "hide",
          showSlide3: "show",
          
        }
      } else if (action.payload.clickedSlide.slide == 'slide3') {
        return {
          ...state,
          showSlide3: "hide",
          showSlide4: "show"
        }
      } 
      else if (action.payload.clickedSlide.slide == 'slide4') {
        return {
          ...state,
          showSlide4: "hide",
          showSlide5: "show"
        }
      }
      else if (action.payload.clickedSlide.slide == 'slide5') {
        return {
          ...state,
          showSlide5: "hide",
          showSlide6: "show"
        }
      }
      
      else {
        return {
          ...state
        }
      }
    }
    case 'previousSlides': {
      if (action.payload.previousSlide.slide == 'backslide2') {
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide"
        }
      } else if (action.payload.previousSlide.slide == 'backslide3') {
        return {
          ...state,
          showSlide2: "show",
          showSlide3: "hide"
        }
      } else if (action.payload.previousSlide.slide == 'backslide4') {
        return {
          ...state,
          showSlide3: "show",
          showSlide4: "hide"
        }
      } else if (action.payload.previousSlide.slide == 'backslide5') {
          return {
            ...state,
            showSlide4: "show",
            showSlide5: "hide"
          }
      } 
      else {
        return {
          ...state
        }
      }
    }
  }
}
const FormQIL_A_VWO = ({}) => {
  
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [completed,setCompleted] = useState("0%");

  const { queryString } = useContext(QueryStringContext);
  const splitForm = useRef(null);
  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const [bankSelectedValue, setBankSelectedValue] = useState([]);
  const [otherBankSelectedList, setOtherBankSelectedList] = useState([]);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const [loaderClass, setLoaderClass] = useState("load");
  const [loaderStatus, setLoaderStatus] = useState("hide");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  // **********form submit*************************************************
  const formSubmit = async () => {
    setLoaderClass("load");
    setLoaderStatus("show");
    setBtnDisabled(true);
    const form = splitForm.current;
    const values = getValues();
    console.log(values);
    const formData = values;
    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }
    // let bankIds = bankSelectedValue.map((a) => a.id);
    // let otherBankIds = otherBankSelectedList.map((a) => a.id);
    // formData.bankList = bankIds;
    // formData.otherBankList = otherBankIds;
    formData.page_name = 'insured-life/life_insurance/QIL_A';

    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      // setFormParameters(formData);
      // setter
      const username=formData.txtFName+ ' ' +formData.txtLName;
      localStorage.setItem('username',username);
      // localStorage.setItem('queryString',queryString );
      
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        'split_form_submit',
        'insured-life/life_insurance/QIL_A_VWO',
        queryString,
        visitorParameters.data
      )
      if (formSUbmitResult.data.status === "Success") {
        if (queryString != null) {
          if (queryString.indexOf("&" + field + "=") !== -1) {
            history.push("/fbpixel");// fb fixel firing
          } else {
            history.push(
             // "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
             "/thankyou"
            );
          }
        } else {
          history.push(
         //   "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
         "/thankyou"
          );
        }
      }
    }
  };
  // ******************************Slide Chnage***************************************
  const slideChange = (slideName) => {
    window.scrollTo(0, 0);
    setClickedSlide({ slide: slideName });
    // setCompleted("40%");
  };

  const slideBar = (progressBar) => {
    setCompleted(progressBar);
  };
  const previousSlideChange = (slideName) => {
    window.scrollTo(0, 0);
    //console.log(slideName.target.id)
    if(slideName.target.id){
      setCompleted(slideName.target.id);
    }
    setPreviousSlide({ slide: slideName.target.name });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: 'showSlide', payload: { clickedSlide } });
    }
  }, [clickedSlide]);
  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: 'previousSlides', payload: { previousSlide } });
    }
  }, [previousSlide]);
  // *********************Address validation*******************************************
  return (
    <form
      ref={splitForm}
      name="split_form"
      id="user_form"
      method="POST"
    >
        {/*
      <!-- ========= SLIDE ONE START ========= -->*/}
        <Progressbar value={completed} className={(state.showSlide1 ==='hide' ) ? 'block': 'none' }/>

      <Question1 
      className={state.showSlide1}
      slideChange={slideChange}
      backClick={previousSlideChange}
      validation={register}
      validationMsg={errors}
      progressBar={slideBar}
      />
      
      {/*
      <!-- ========= SLIDE ONE END ========= -->*/} {/*
      <!-- ========= SLIDE TWO START ========= -->*/}
      
      <Question2
      className={state.showSlide2}
      slideChange={slideChange}
      backClick={previousSlideChange}

      validation={register}
      validationMsg={errors}
      progressBar={slideBar}
      />
      {/*
      <!-- ========= SLIDE TWO END ========= -->*/} {/*
      <!-- ========= SLIDE THREE START ========= -->*/}

          <DobSlide
          className={state.showSlide3}
          slideChange={slideChange}
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          backClick={previousSlideChange}
          progressBar={slideBar}


          />
      
      {/* <!-- ========= SLIDE THREE END ========= -->
      <!-- ========= SLIDE FOUR START ========= --> */}
         <PostcodeDetails
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          textContent="Your Current Address"
          onBackClick={previousSlideChange}
          className={state.showSlide4}
          slideChange={slideChange}
          backClick={previousSlideChange}
          progressBar={slideBar}


        />

      {/*
      <!-- ========= SLIDE FOUR END ========= -->*/} {/*
      <!-- ========= SLIDE FIVE START ========= -->*/}
          <ContactDetails
            className={state.showSlide5}
            slideChange={slideChange}
            validation={register}
            validationMsg={errors}
            setError={setError}
            trigger={trigger}
            getValues={getValues}
            backClick={previousSlideChange}
            formSubmit={formSubmit}
            progressBar={slideBar}
            />
      {/*
      <!-- ========= SLIDE FIVE END ========= -->*/}
      
  </form>
  );
};

export default FormQIL_A_VWO;