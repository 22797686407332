import React, { useEffect, useState } from "react";
import RadioButton from "../../UI/RadioButton";

const Question2 = ({
  className,
  slideChange,
  textHeading,
  validation,
  backClick,
  progressBar,
}) => {
  const [checkStatusThree,setcheckStatusThree] = useState("unchecked");
  const [checkStatusFour,setcheckStatusFour] = useState("unchecked");
  const [checkStatusFive,setcheckStatusFive] = useState("unchecked");
  const nextSlide = async (e) => {
    if(e.target.value == 3){
      setcheckStatusThree("checked");
      setcheckStatusFour("unchecked");
      setcheckStatusFive("unchecked");
    }
    if(e.target.value == 4){
      setcheckStatusFour("checked");
      setcheckStatusThree("unchecked");
      setcheckStatusFive("unchecked");
    }
    if(e.target.value == 5){
      setcheckStatusFive("checked");
      setcheckStatusFour("unchecked");
      setcheckStatusThree("unchecked");
    }
    slideChange("slide2");
   progressBar("50%");
  };
  return (
    <>
      {/* <Progressbar value="25%" className={className} /> */}

      <div id="slide2" className={className}>
        <div className="col-lg-12 form_2">
          <div className="col-lg-12 row custom m-0">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 txt-lft">
              <p className="hdding py-2">What would you like to cover?</p>
              <ul className="icon_buttons">
                <li className="mr" style={{marginRight:"5px"}}>
                  <label htmlFor="3" className="next_02 next-but">
                    <div className={`icon_butn smoker2 ${checkStatusThree}`}>
                      <RadioButton
                        value="3"
                        name="question_2"
                        className="radio-button"
                        // classInput="radio"
                        // labelName="YES"
                        // labelClass="label-text next02"
                        onClick={nextSlide}
                        id="3"
                        validation={validation()}
                      />
                      {/* <input type="radio"  className="radio-button" name="coverfor" id="smoker-yes1" value="My Family"/> */}
                      <img
                        className="original-image"
                        src="/assets/QIL_A/img/family_blue.png"
                      />
                      <img
                        className="active-image"
                        src="/assets/QIL_A/img/family.png"
                      />
                    </div>
                  </label>
                  <p className="cover_text">
                    My <br /> Family
                  </p>
                </li>
                <li className="mr" style={{marginRight:"5px"}}>
                  <label
                    htmlFor="4"
                    className="next_02 next-but sctop"
                  >
                    <div className={`icon_butn smoker2 ${checkStatusFour}`}>
                      <RadioButton
                        value="4"
                        name="question_2"
                        className="radio-button"
                        // classInput="radio"
                        // labelName="YES"
                        // labelClass="label-text next02"
                        onClick={nextSlide}
                        id="4"
                        validation={validation()}
                      />
                      {/* <input type="radio" className="radio-button" name="coverfor" id="smoker-no1" value="My Mortgage"/> */}
                      <img
                        className="original-image"
                        src="/assets/QIL_A/img/home_blue.png"
                      />
                      <img
                        className="active-image"
                        src="/assets/QIL_A/img/home.png"
                      />
                    </div>
                  </label>
                  <p className="cover_text">
                    My <br /> Mortgage
                  </p>
                </li>
                <li>
                  <label
                    htmlFor="5"
                    className="next_02 next-but sctop"
                  >
                    <div className={`icon_butn smoker2 ${checkStatusFive}`}>
                      <RadioButton
                        value="5"
                        name="question_2"
                        className="radio-button"
                        // classInput="radio"
                        // labelName="YES"
                        // labelClass="label-text next02"
                        onClick={nextSlide}
                        id="5"
                        validation={validation()}
                      />
                      {/* <input type="radio" className="radio-button" name="coverfor" id="smoker-no2" value="Family &amp; Mortgage"/> */}
                      <img
                        className="original-image"
                        src="/assets/QIL_A/img/fam_home_blue.png"
                      />
                      <img
                        className="active-image"
                        src="/assets/QIL_A/img/fam_home.png"
                      />
                    </div>
                  </label>
                  <p className="cover_text">
                    {" "}
                    Family and
                    <br /> Mortgage
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 text-center mt-1">
          <a
            id="50%"
            className="back_btn sctop"
            name="backslide2"
            onClick={backClick}
          >
            <img src="/assets/QIL_A/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className="text-center safe-text">
          <img
            src="/assets/QIL_A/img/ico-safe.png"
            alt="Safe, Secure &amp; Confidential"
            width="12"
            height="15"
          />
          Safe | Secure | Privacy Protected
        </div>
      </div>
    </>
  );
};
export default Question2;
