import React from "react";
import { Link } from "react-router-dom";

const Anchor = ({
  className,
  children,
  href = "#",
  anchorStyle,
  onClick,
  idName,
}) => {
  return (
    <>
      <Link
        to={href}
        //target="_blank"
        style={anchorStyle}
        className={className}
        id={idName}
        onClick={onClick}
      >
        {children}
      </Link>
    </>
  );
};
export default Anchor;
