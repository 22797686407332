import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import './Utility/Sentry.js';


ReactDOM.render(
    <App/>,
    document.querySelector("#root")
);