import React,{useEffect,useState,useContext} from 'react';
import  '../../../assets/Thankyou/css/Thankyou.scss';
import ThankyouHeader from "../../Includes/Layouts/ThankyouHeader";
import ThankyouFooter from '../../Includes/Layouts/ThankyouFooter';
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import RadioButton from "../../UI/RadioButton";
import {useThankyou} from "../../../Hooks/useThankyou";
const Thankyou = () =>{

    useEffect(() => {
        document.title = "Thank You - Insured Life"
     }, []);

     const userName =localStorage.getItem('username');
     const [contactTime, setContactTime] = useState("");
     const [showForm, setshowForm] = useState("show");
     const [validation, setValidation] = useState(true);
     const { saveThankyou, isLoading } = useThankyou();
     const { visitorParameters } = useContext(VisitorParamsContext);
     const query_string = localStorage.getItem('queryString')
     const formParameters = JSON.parse(localStorage.getItem('formData'));

     const setRadio = async (e) => {
      setContactTime(e.target.value);
      setValidation(true);
      
      };

     const validateSlide = async (e) => {
        if(contactTime=="")
        {
         setValidation(false);
        }
        else{
         setValidation(true);
         const response = await saveThankyou(
            contactTime,
            visitorParameters,
            formParameters,
            query_string
        );
        console.log(response);
        if(response.data.status=="Success")
        {
         setshowForm("hide");
        }
        
   //    setTimeout(function () {
   //       setshowForm("hide");
   //   }, 5000);
      //   setshowForm("hide");
         // await saveThankyou()
        }
     
     
    };

    return (
    <div className="Ty_page">
            <div className="ty-body">

            <ThankyouHeader />
        <section>
         <div className="container">
            <div className="row">
               <div className="col-lg-6 col-md-12 col-sm-12 col-12 text-left mb_bg">
                  <h1>Thank You,{userName}.</h1>
                  <p>One of our trusted Life Insurance advisors needs to speak with you on the phone briefly to get a bit more information and help determine the best option for you.</p>
                  <p>And don’t worry! This phone call does NOT commit you to buying anything and is simply to help you choose the best option.</p>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 col-12 form_div ">
                  <form className={showForm} name="ty-form" id="ty-id">
                     <h3>What is the best time to contact you? </h3>
                     <div className="formn">
                        <ul className="options">

                           <li className="popupLink pop_link1 active" id="popupLink1">
                           <RadioButton
                              value="6"
                              name="caltime"
                              className="custom-checkbox bank-name"
                              onClick={setRadio}
                              id="option1"
                              // validation={validation()}
                           />
                           <label htmlFor="option1">Anytime</label> 
                           </li>
                           <li className="popupLink pop_link2" id="popupLink2">
                              <RadioButton
                                 value="7"
                                 name="caltime"
                                 className="custom-checkbox bank-name"
                                 onClick={setRadio}
                                 id="option2"
                                 // validation={validation()}
                              />
                              <label htmlFor="option2">Morning</label> 
                              
                           </li>

                           <li className="popupLink pop_link3" id="popupLink3">
                              <RadioButton
                                 value="8"
                                 name="caltime"
                                 className="custom-checkbox bank-name"
                                 onClick={setRadio}
                                 id="option3"
                                 // validation={validation()}
                              />
                              <label htmlFor="option3">Afternoon</label> 
                              
                           </li>

                           <li className="popupLink pop_link4" id="popupLink4">
                              <RadioButton
                                 value="9"
                                 name="caltime"
                                 className="custom-checkbox bank-name"
                                 onClick={setRadio}
                                 id="option4"
                                 // validation={validation()}
                              />
                              <label htmlFor="option4">Evening</label> 
                              
                           </li>

                        </ul>
                        {!validation && (
                        <span className="error_msg">
                              Please Select any option
                        </span>
                     )}
                     </div>
                     
                     <div className="text-center">
                        <input
                        type="button"
                        className="btn sub_but"
                        value="Submit"
                        onClick={validateSlide}
                        />
                     </div>
                     {(isLoading)? <h3>Please wait...</h3>:null}
                  </form>
               </div>
            </div>
         </div>
      </section>
    
        <section className="fut">
         <div className="container">
            <div className="col-lg-12">

               <p className="abt_txt text-center">
                  Insured Life is the trading name of PBS & Co (UK) Limited who are authorised and regulated by 
                  the Financial Conduct Authority (FCA) for insurance mediation under the firm license number: 732728. 
                  Insured-Life.co.uk is an insurance brokerage firm which provides both life and health insurance cover.
                  PBS & Co Limited is registered in England and Wales (Company No. 09868404).
               </p>
            </div>
         </div>
      </section>
            <ThankyouFooter  /> </div>
         </div>
    )
}

export default Thankyou;
