import React from "react";

const Telephone = ({ placeholder, name, className, validation, onBlur }) => {
  return (
    <input
      type="tel"
      name={name}
      className={className}
      placeholder={placeholder}
      ref={validation}
      onBlur={onBlur}
    />
  );
};
export default Telephone;
