import React from "react";

const Footer = ({CP_V1Style}) => {
  return (
    <>
       <section className="fut">
        <div className="container">
            <div className="col-lg-12">
                <div className="text-center log">
                    <a href="#"><img src="/assets/QIL_A/img/logo.png" alt="" className="footer_logo" /></a>
                </div>
                <p className="abt_txt text-center">
                    Insured Life is the trading name of PBS & Co (UK) Limited who are authorised and regulated by the Financial Conduct Authority (FCA) for insurance mediation under the firm license number: 732728. Insured-Life.co.uk is an insurance brokerage firm which
                    provides both life and health insurance cover. PBS & Co Limited is registered in England and Wales (Company No. 09868404).
                </p>
            </div>
        </div>
     </section>
      <footer>
        <ul>
            <li><a href="https://www.insured-life.co.uk/privacy-policy/?_ga=2.183440678.1636172330.1624982787-1708974579.1610448288" target="blank">Privacy Policy</a></li>
            <li><a href="https://www.insured-life.co.uk/cookie-policy/" target="blank">Cookie Policy</a></li>
        </ul>
        <div className="container">
            <div className="row text-center justify-content-center">
                <div className="col-lg-10">
                    <p>©2021 Insured Life. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    </footer> 
    </>
  );
};

export default Footer;
