import React from 'react';
import { DobYear, DobMonth, DobDay } from "../../Constants/Constants";
import SelectBox from '../UI/SelectBox';


const DateOfBirthVwo = ({ textDob, validation, validationMsg, CP_V1Style }) => {

    return (
        // new code here


        <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4 mt-1 dmy">
                <SelectBox
                    className="form-control not_chosen"
                    OptionValue={DobDay}
                    name="lstDobDay"
                    myRef={validation({ 
                                })} 
                    validationMsg={validationMsg.lstDobDay && validationMsg.lstDobDay.message}
                />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 mt-1 dmy">
                <SelectBox
                    className="form-control not_chosen"
                    OptionValue={DobMonth}
                    name="lstDobMonth"
                    myRef={validation({
                                })} 
                    validationMsg={validationMsg.lstDobMonth && validationMsg.lstDobMonth.message}
                />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 mt-1 dmy">
                <SelectBox
                    className="form-control"
                    OptionValue={DobYear}
                    name="lstDobYear"
                    myRef={validation({ 
                                })} 
                    validationMsg={validationMsg.lstDobYear && validationMsg.lstDobYear.message}
                />
            </div>
            <span id="fullDOB_err" className="error_msg error"></span>
        </div>


        // ends here


        // <fieldset className={`${CP_V1Style['scheduler-border']} ${CP_V1Style['anim_ylw']}`}>
        //     <legend className="scheduler-border">{textDob}</legend>
        //     <div className="row">
        //         <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        //             <div className="form-group">
        //                 <SelectBox 
        //                     className="form-control"
        //                     OptionValue={DobDay}
        //                     name = "lstDobDay"
        //                     myRef={validation({ required: "Please Select Date"
        //                         })} 
        //                     validationMsg={validationMsg.lstDobDay && validationMsg.lstDobDay.message}
        //                 />
        //                 <i className="tick fa" style={{display:'none'}}></i>
        //                 {/* <span id="dobDay_err" className="error_msg"></span> */}
        //             </div>
        //         </div>
        //         <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        //             <div className="form-group">
        //                 <SelectBox 
        //                     className="form-control"
        //                     OptionValue={DobMonth}
        //                     name = "lstDobMonth"
        //                     myRef={validation({ required: "Please Select Month"
        //                         })} 
        //                     validationMsg={validationMsg.lstDobMonth && validationMsg.lstDobMonth.message}
        //                 />
        //                 <i className="tick fa" style={{display:'none'}}></i>
        //                 {/* <span id="dobMonth_err" className="error_msg"></span> */}
        //             </div>
        //         </div>
        //         <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        //             <div className="form-group">
        //                 <SelectBox 
        //                     className="form-control"
        //                     OptionValue={DobYear}
        //                     name = "lstDobYear"
        //                     myRef={validation({ required: "Please Select Year"
        //                         })} 
        //                     validationMsg={validationMsg.lstDobYear && validationMsg.lstDobYear.message}
        //                 />
        //                 <i className="tick fa"  style={{display:'none'}}></i>
        //                 {/* <span id="dobYear_err" className="error_msg"></span> */}
        //             </div>
        //         </div>
        //         <span id="dob_final_err" className="error_msg"></span>
        //     </div>
        // </fieldset>
    )
}

export { DateOfBirthVwo };