import React, { useEffect, useState } from "react";
import { DateOfBirthVwo } from "../DateOfBirthVwo";

const DobSlide = ({
  className,
  slideChange,
  textHeading,
  validation,
  validationMsg,
  trigger,
  backClick,
  progressBar,
}) => {
  const nextSlide = async (e) => {
    // var lstDobDayResult = await trigger("lstDobDay");
    // var lstDobMonthResult;
    // var lstDobYearResult;
    // if (lstDobDayResult) {
    //   lstDobMonthResult = await trigger("lstDobMonth");
    // } else {
    //   return false;
    // }

    // if (lstDobMonthResult) {
    //   lstDobYearResult = await trigger("lstDobYear");
    // } else {
    //   return false;
    // }

    // if (lstDobDayResult && lstDobMonthResult && lstDobYearResult) {
      slideChange("slide3");
      progressBar("75%");
    // } else {
    //   return false;
    // }
  };

  return (
    <>
      {/* <Progressbar value="50%" className={className} /> */}
      <div id="slide3" className={className}>
        <div className="col-lg-12 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
            <label className="py-2">
              Enter your date of birth to see what policies you qualify for
            </label>
            <DateOfBirthVwo
              validation={validation}
              validationMsg={validationMsg}
            />
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <input
            type="button"
            id="next02"
            name="next02"
            className="next-but btn my-bt sctop next_03"
            value="Continue >>"
            onClick={nextSlide}
          />
          <div className="col-12 text-center mt-1">
            <a
              id="25%"
              className="back_btn sctop"
              name="backslide3"
              onClick={backClick}
            >
              <img src="/assets/QIL_A/img/arrow.png" alt="" /> Previous{" "}
            </a>
          </div>
          <div className="text-center safe-text">
            <img
              src="/assets/QIL_A/img/ico-safe.png"
              alt="Safe, Secure &amp; Confidential"
              width="12"
              height="15"
            />
            Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default DobSlide;
