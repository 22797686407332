import React from "react";
import Email from "../../UI/Email";
import Telephone from "../../UI/Telephone";
import InputButton from "../../UI/InputButton";
import TextField from "../../UI/TextField";
import { useEmailValidation } from "../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../Hooks/usePhoneValidation";

const ContactDetails = ({
  validation,
  validationMsg,
  setError,
  className,
  backClick,
  trigger,
  loaderClass,
  loaderStatus,
  disabled,
  getValues,
  formSubmit,
  CP_V1Style,
  progressBar,
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();

  const validateSlide = async (e) => {
    e.preventDefault();
    // alert("test");
    const values = getValues();
    var fnameValidation = await trigger("txtFName");
    var lnameValidation, emailEmpty, phoneEmpty;
    const newValues = getValues();
    const fname = values["txtFName"];
    const lname = values["txtLName"];
    const emailCheck = values["txtEmail"];

    const phoneCheck = values["txtPhone"];
    const dobDay = values["lstDobDay"];
    const dobMonth = values["lstDobMonth"];
    const dobYear = values["lstDobYear"];
    const postcode = values["txtPostCode"];
    const address = values["address1"];

    if (
      dobDay === "" ||
      dobMonth === "" ||
      dobYear === "" ||
      postcode === "" ||
      fname === "" ||
      lname === "" ||
      emailCheck === "" ||
      phoneCheck === ""
      // address === ""
    ) {

      if (fname === "") {
        setError("txtFName", {
          type: "manual",
          message: "Please Enter First Name",
        });
      }
      if (lname === "") {
        setError("txtLName", {
          type: "manual",
          message: "Please Enter Last Name",
        });
      }

      if (emailCheck === "") {
        setError("txtEmail", {
          type: "manual",
          message: "Please Enter Valid Email Address",
        });
      }
      if (phoneCheck === "") {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
      }
      if (dobDay === "") {
        setError("lstDobDay", {
          type: "manual",
          message: "Please Select Date",
        });
      }
      if (dobMonth === "") {
        setError("lstDobMonth", {
          type: "manual",
          message: "Please Select Month",
        });
      }
      if (dobYear === "") {
        setError("lstDobYear", {
          type: "manual",
          message: "Please Select Year",
        });
      }
      if (postcode === "") {
        setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Postcode",
        });
      }
      // if (address === "") {
      //   setError("address1", {
      //     type: "manual",
      //     message: "Please Select Address",
      //   });
      // }

      return false;
    }
    // await trigger("txtLName");

    // await trigger("txtEmail");
    // await trigger("txtPhone");
    // if (
    //   fname &&
    //   lname &&
    //   emailCheck &&
    //   phoneCheck &&
    //   dobDay &&
    //   dobMonth &&
    //   dobYear &&
    //   postcode &&
    //   address
    // ) {
    if ((await phoneValidCheck()) === 1 && (await emailValidCheck()) === 1) {
      var validate = await trigger();
      console.log(validate);
      if (validate) {
        formSubmit();
      }
      else {
        return false;
      }

    }
    // }
  };

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      }
      else if(getPhoneValidation.data.status_code === 2){
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } 
      else if(getPhoneValidation.data.status_code === 3){
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }
      else {
        return 1;
      }
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email);
      if (getEmailValidation.data.status_code === 0) {
        setError("txtEmail", {
          type: "manual",
          message: "Email Already Exist",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };

  return (
    <>
      {/* //     <Progressbar value="100%" className={className} /> */}
      <div id="slide5" className={className}>
        <div className="col-lg-12 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group text-center">
            <label className="mobpad10 py-2">
              Who would this policy be for?
            </label>
            <div className="row_style">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 sizer2">
                <TextField
                  type="text"
                  className="form-control sizer"
                  placeholder="First Name"
                  name="txtFName"
                  validation={validation({
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid First Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                    },
                  })}

                ></TextField>

              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 sizer2">
                <TextField
                  type="text"
                  className="form-control sizer"
                  placeholder="Last Name"
                  name="txtLName"
                  validation={validation({
                    minLength: {
                      value: 2,
                      message: "Please Enter Valid Last Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Last Name is Invalid. Please Recheck",
                    },
                  })}

                ></TextField>
                <i className="tick fa validate success1 fa-check"></i>
                <span id="post_err" className="error_msg"></span>
              </div>
              <div className="clearfix"></div>

              <div className="col-lg-6 col-md-12 col-sm-12 col-12 input-group form-group sizer2">
                <Email
                  name="txtEmail"
                  className="form-control add_prvcy"
                  placeholder="Email Address"
                  onBlur={emailValidCheck}
                  validation={validation({
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid Email Address",
                    },
                  })}

                ></Email>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <img
                      src="http://funeral-planning.co.uk/splits/0602FP00-P/dist/img/privacy.png"
                      alt=""
                      className="privcy pull-right"
                    />
                  </span>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 col-12 input-group form-group sizer2">
                <Telephone
                  name="txtPhone"
                  className="form-control  add_prvcy"
                  placeholder="Phone Number"
                  onBlur={phoneValidCheck}
                  validation={validation({
                    // required: "Please Enter Valid Phone Number",
                    pattern: {
                      value: /^[0-9]*$/i,
                      message: "Please Enter Valid Phone Number",
                    },
                  })}
                ></Telephone>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <img
                      src="http://funeral-planning.co.uk/splits/0602FP00-P/dist/img/privacy.png"
                      alt=""
                      className="privcy pull-right"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {validationMsg.txtFName && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtFName.message}
            </span>
          )}
          {validationMsg.txtLName && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtLName.message}
            </span>
          )}
          {validationMsg.txtEmail && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtEmail.message}
            </span>
          )}
          {validationMsg.txtPhone && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtPhone.message}
            </span>
          )}
          {validationMsg.lstDobDay && (
            <span className="error_msg " id="email_err">
              {validationMsg.lstDobDay.message}
            </span>
          )}
          {validationMsg.lstDobMonth && (
            <span className="error_msg " id="email_err">
              {validationMsg.lstDobMonth.message}
            </span>
          )}
          {validationMsg.lstDobYear && (
            <span className="error_msg " id="email_err">
              {validationMsg.lstDobYear.message}
            </span>
          )}
          {validationMsg.txtPostCode && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtPostCode.message}
            </span>
          )}
          {validationMsg.txtHouseNumber && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtHouseNumber.message}
            </span>
          )}
          {validationMsg.txtTown && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtTown.message}
            </span>
          )}
          {validationMsg.txtCountry && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtCountry.message}
            </span>
          )}
          <div className="col-lg-12 col-md-12 col-sm-12 bord text-center p-0">
            <input
              type="button"
              className="next-but btn my-bt next_05"
              value="Compare Quotes >>"
              onClick={validateSlide}
            />
            {/* <!-- <input type="button" id="animabt1" style="display: none;" name="" className="next-but btn my-btttnes anim_ylw" value="Compare Quotes >>"> --> */}
            <div className="col-12 text-center mt-1">
              <a
                id="75%"
                name="backslide5"
                onClick={backClick}
                className="back_btn sctop"
              >
                <img src="/assets/QIL_A/img/arrow.png" alt="" /> Previous{" "}
              </a>
            </div>
            <div className="text-center safe-text">
              <img
                src="/assets/QIL_A/img/ico-safe.png"
                alt="Safe, Secure &amp; Confidential"
                width="12"
                height="15"
              />{" "}
              Safe | Secure | Privacy Protected
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <div className="offset-lg-2 col-lg-8 col-lg-offset-2 terms mob-0">
              <div className="consent text-center">
                {/* <p style="color:#000"> */}
                <p>
                  By submitting your enquiry, you agree to our{" "}
                  <a
                    href="https://www.insured-life.co.uk/privacy-policy/?_ga=2.183440678.1636172330.1624982787-1708974579.1610448288"
                    target="blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  and agree we may use the contact details provided to contact
                  you about your Life Insurance plan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactDetails;
