
import {useState} from 'react'; 
import {Api} from "../api/Api";

export const useThankyou = () => {
    const [isLoading,setIsLoading] = useState(false);

    const saveThankyou = async (question_3,visitor_parameters,formParameters,query_string,message_type='thankyou_store') => {
        setIsLoading(true);
        const thankyouSubmit = await Api.post("api/v1/data-ingestion-pipeline", {
            message_type,
            question_3,
            visitor_parameters:visitor_parameters.visitor_parameters,
            form_data:formParameters,
            data:visitor_parameters.data,
            query_string:query_string
        });
        setIsLoading(false);
        return thankyouSubmit;
    }
    return { 
        isLoading,
        saveThankyou
    }
}