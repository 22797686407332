import React from "react";

const ThankyouFooter = () => {
  return (
    <>
        <footer>
         <ul>
            <li><a href="https://www.insured-life.co.uk/privacy-policy/?_ga=2.183440678.1636172330.1624982787-1708974579.1610448288" target="blank">Privacy Policy</a></li>
            <li><a href="https://www.insured-life.co.uk/cookie-policy/" target="blank">Cookie Policy</a></li>
         </ul>
         <div className="container">
            <div className="row text-center justify-content-center">
               <div className="col-lg-10">
                  <p>©2021 Insured Life. All Rights Reserved.</p>
               </div>
            </div>
         </div>
      </footer>
    </>
  );
};

export default ThankyouFooter;
