import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { Api } from "./api/Api";
import queryString from "./Utility/QueryString";
import FbPixel from "./Components/Pages/Splits/FbPixel";
import Matomo from "./Components/Includes/Matomo";
import "./App.scss";
import QIL_A from "./Components/Pages/Splits/QIL_A";
import QIL_A_VWO from "./Components/Pages/Splits/QIL_A_VWO";
import Thankyou from "./Components/Pages/Splits/Thankyou";

const App = () => {
  const [visitorParameters, setVisitorParameters] = useState({});
  const referrer = document.referrer;
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
    (async () => {
      const response = await Api.post("api/v1/user-agent", {
        user_agent: window.navigator.userAgent,
        page_type: "LP",
        query_string: queryString,
        domain_name: EnvConstants.AppDomain,
        existingdomain: window.location.href,
        referer_site: referrer,
      });
      const visitorData = response.data.response.visitor_parameters;
      const DataParams = response.data.response.data;
      setVisitorParameters({
        visitor_parameters: visitorData,
        data: DataParams,
      });
    })();
  }, []);
  return (
    <>
      {EnvConstants.AppEnv==='prod'
        ? <Matomo/>
        : ''
      }
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <BrowserRouter>
            <Route exact path={["/", "/insured-life/life_insurance/QIL_A"]} component={QIL_A} />
            <Route exact path={["/insured-life/life_insurance/QIL_A_VWO"]} component={QIL_A_VWO} />
            <Route exact path={["/thankyou"]} component={Thankyou} />
            <Route exact path={["/fbpixel"]} component={FbPixel} />
          </BrowserRouter>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};
export default App;
