import React from "react";
import  GoogleTag from '../../Includes/GoogletagManager';
import FbViewPixel  from '../../Includes/FbViewPixel';

const SplitHeadBanner = ({ head, subHead ,CP_V1Style}) => {
  return (
    <>
    {/* <GoogleTag/> */}
    <FbViewPixel/>
    <header className="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-block d-md-block d-sm-block d-none" id="page-top">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <img src="/assets/QIL_A/img/logo.png" alt="" className="logo" />
                </div>
            </div>
        </div>
    </header>
    </>
  );
};

export default SplitHeadBanner;