import { React, useReducer } from "react";
import TextField from "../../UI/TextField";
import Anchor from "../../UI/Anchor";
import SelectBox from "../../UI/SelectBox";
import HiddenField from "../../UI/HiddenField";
import InputButton from "../../UI/InputButton";
import { usePostCodeAddress } from "../../../Hooks/usePostCodeAddress";
import Progressbar from "../../Includes/Progressbar";

// const PostcodeDetails = ({ className, slideChange, textHeading,validation }) => {
const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 1,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    default:
      return state;
  }
};
const PostcodeDetails = ({
  className,
  validation,
  validationMsg,
  onBackClick,
  clearErrors,
  setError,
  slideChange,
  trigger,
  backClick,
  progressBar,
}) => {
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const checkValidation = async (e) => {
    e.preventDefault();
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      const getValidation = await getPostcodeValidation(e.target.value);
      if (getValidation.data === 0) {
        setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
      }
      if (getValidation.data === 1) {
        const addressList = await getPostcodeAddress(e.target.value);
        if (addressList) {
          dispatch({
            type: "validationSucess",
            payload: {
              lookup: false,
              next_click: true,
              postcode: e.target.value,
              get_address: addressList.data,
              check_select: true,
              manual_address: "hide",
              manual_click: 1,
            },
          });
        }
      }
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    clearErrors([
      "txtHouseNumber",
      "txtAddress3",
      "txtCounty",
      "address1",
      "txtTown",
      "txtCountry",
    ]);
    const getData = await getSplitPostcodeAddress(
      e.target.value,
      state.getPostcode
    );
    dispatch({
      type: "getExtraAddressDetails",
      payload: {
        get_details: getData.data,
        manual_address: "show",
        manual_click: 0,
      },
    });
  };

  const addressValidation = async (e) => {
    let errorFlag = 0;
    const txtPostCode = await trigger("txtPostCode");
    if (state.clickManualLink === 1) {
      const address1 = await trigger("address1");
      if (!address1) {
        return false;
      }
    }
    const manual_txtHouseNumber = await trigger("txtHouseNumber");
    var manual_txtTown;
    var manual_txtCountry;
    if (manual_txtHouseNumber) {
      manual_txtTown = await trigger("txtTown");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountry");
    } else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtHouseNumber ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (errorFlag === 0) {
      slideChange("slide4");
      progressBar("100%");
    } else {
      return false;
    }
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.Line3 !== "#" ? state.getDetails.Line3 : ""
        }
      />
    </>
  );

  return (
    <>
      {/* <Progressbar value="75%" className={className} /> */}
      <div id="slide4" className={className}>
        <div className="col-lg-12 form_cont">
          <div className="row">
            <div className="col-lg-12 backpading">
              <div className="offset-lg-3 col-lg-6 col-md-8 offset-md-2 col-12 pass">
                <div className="form-group col-12">
                  <label className="mobpad10 py-2">Where do you live?</label>
                  <TextField
                    type="text"
                    placeholder="Postcode"
                    name="txtPostCode"
                    className="form-control"
                    dataId="txtPostCode"
                    onBlur={checkValidation}
                    onChange={() => {
                      dispatch({
                        type: "validationSucess",
                        payload: {
                          lookup: true,
                          next_click: false,
                          check_select: false,
                          manual_address: "hide",
                          get_address: [],
                          manual_click: 1,
                        },
                      });
                    }}
                    autoComplete="off"
                    validation={validation({
                      required: "Please Enter Postcode",
                      pattern: {
                        value: /^(?:[A-Za-z]+)(?:[A-Za-z0-9\s]*)$/,
                        message: "Please Enter a Valid Postcode",
                      },
                      minLength: {
                        value: 5,
                        message: "Please Enter a Valid Postcode",
                      },
                    })}
                    validationMsg={
                      validationMsg.txtPostCode &&
                      validationMsg.txtPostCode.message
                    }
                  />{" "}
                  <i className="tick fa validate success1 fa-check"></i>
                  <span id="post_err" className="error_msg"></span>
                  <div
                    className="form-group col-12 text-center hide"
                    id="postbtndiv"
                  >
                    {/* <button id="postcodevalid" style="float:none;" className="btn_disable scrolltopbt" type="button">Lookup Address</button> */}
                    <button
                      id="postcodevalid"
                      className="btn_disable scrolltopbt"
                      type="button"
                    >
                      Lookup Address
                    </button>
                  </div>
                </div>
                <div
                  className="form-group text-center"
                  style={
                    state.clickManualLink === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <Anchor
                    className="manual-entry-request mb-2"
                    idName="manual-entry-btn"
                    onClick={() => {
                      dispatch({
                        type: "showManualDiv",
                        payload: { manual_address: "show", manual_click: 0 },
                      });
                    }}
                    children="[ Having Issues? Click Here to Enter Manually ]"
                  ></Anchor>
                </div>

                <div
                  id="currentAddressCollapse"
                  style={
                    state.checkSelect
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div className="form-group col-12">
                    <SelectBox
                      className="form-control next03  animated-effect watermark sctop"
                      OptionValue={state.getAddress}
                      name="address1"
                      onChange={getValue}
                      myRef={validation({ required: "Please select address" })}
                      validationMsg={
                        validationMsg.address1 && validationMsg.address1.message
                      }
                    />
                    <span id="address1_error" className="error_msg"></span>
                    <i className="validate tick fa hide" aria-hidden="true"></i>
                    <div className="error_msg "></div>
                  </div>
                </div>
                <div className={state.showManualAddress}>
                  <div className="form-group col-12 ">
                    <TextField
                      type="text"
                      placeholder="Company"
                      name="txtHouseName"
                      className="form-control"
                      dataId="txtHouseName"
                      autoComplete="off"
                    
                      validation={validation()}
                      validationMsg={
                        validationMsg.txtHouseName &&
                        validationMsg.txtHouseName.message
                      }
                      value={state.getDetails.Company}
                    />
                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    ></i>
                    <span id="addr_err" className="error_msg"></span>
                  </div>
                  <div className="form-group col-12">
                    <TextField
                      type="text"
                      placeholder="Address Line 1 *"
                      name="txtHouseNumber"
                      className="form-control  hero-input"
                      dataId="txtHouseNumber"
                      autoComplete="off"
                      onBlur={() => {
                        dispatch({
                          type: "showManualDiv",
                          payload: { manual_address: "show", manual_click: 0 },
                        });
                      }}
                      validation={validation({
                        required: "Please Enter Address Line 1",
                      })}
                      validationMsg={
                        validationMsg.txtHouseNumber &&
                        validationMsg.txtHouseNumber.message
                      }
                      value={state.getDetails.Line1}
                    />
                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    ></i>
                  </div>

                  <div className="form-group col-12">
                    <TextField
                      type="text"
                      placeholder="Address Line 2"
                      name="txtAddress3"
                      className="form-control  hero-input"
                      dataId="txtAddress3"
                      autoComplete="off"
                      validation={validation()}
                      validationMsg=""
                      value={state.getDetails.Line2}
                    />
                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    ></i>
                  </div>

                  <div className="form-group col-12 ">
                    <TextField
                      type="text"
                      placeholder="County"
                      name="txtCounty"
                      className="form-control  hero-input"
                      dataId="txtCounty"
                      autoComplete="off"
                      validation={validation()}
                      validationMsg=""
                      value={state.getDetails.County}
                    />
                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    ></i>
                  </div>

                  <div className="form-group col-12">
                    <TextField
                      type="text"
                      placeholder="Town *"
                      name="txtTown"
                      className="form-control  hero-input"
                      dataId="txtTown"
                      autoComplete="off"
                      validation={validation({ required: "Please Enter Town" })}
                      validationMsg={
                        validationMsg.txtTown && validationMsg.txtTown.message
                      }
                      value={state.getDetails.Town}
                    />
                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    ></i>
                  </div>
                  <div className="form-group col-12">
                    <TextField
                      type="text"
                      placeholder="Country *"
                      name="txtCountry"
                      className="form-control  hero-input"
                      dataId="txtCountry"
                      autoComplete="off"
                      validation={validation({
                        required: "Please Enter Country",
                      })}
                      validationMsg={
                        validationMsg.txtCountry &&
                        validationMsg.txtCountry.message
                      }
                      value={state.getDetails.Country}
                    />
                    <i
                      className="validate validate_success"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    ></i>
                  </div>
                  {Object.keys(state.getDetails).length > 0 ? (
                    <HiddenFields />
                  ) : null}
                </div>

                {/*
                                <!-- <input type="hidden" name="txtHouseName" id="txtHouseName" value="">
                    <input type="hidden" name="txtCounty" id="txtCounty" value="">
                    <input type="hidden" name="txtTown" id="txtTown" value="">
                    <input type="hidden" name="txtAddress3" id="txtAddress3" value="">
                    <input type="hidden" name="txtUdprn" id="txtUdprn" value="">
                    <input type="hidden" name="txtDeliveryPointSuffix" id="txtDeliveryPointSuffix" value="">
                    <input type="hidden" name="txtPz_mailsort" id="txtPz_mailsort" value="">
                    <input type="hidden" name="txtCountry" id="txtCountry" value=""> -->*/}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          {/* <input type="button" id="next03" name="next03" className="next_04 next-but btn my-bt" value="Continue >>" /> */}
          <InputButton
            name="slide3"
            className="next_04 next-but btn my-bt"
            id=""
            value="Continue >>"
            btnType="button"
            onClick={addressValidation}
            style={{ float: "none" }}
          />

          <div className="col-12 text-center mt-1">
            <a
              id="50%"
              className="back_btn sctop"
              name="backslide4"
              onClick={backClick}
            >
              <img src="/assets/QIL_A/img/arrow.png" alt="" /> Previous{" "}
            </a>
          </div>
          <div className="text-center safe-text">
            <img
              src="/assets/QIL_A/img/ico-safe.png"
              alt="Safe, Secure &amp; Confidential"
              width="12"
              height="15"
            />
            Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default PostcodeDetails;
